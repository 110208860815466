import React, { useEffect, useState } from 'react'
import Footer from '../componets/footer'
import { Link } from 'react-router-dom'
import EnquiryForm from '../componets/EnquiryForm'
import ServiceData from '../Data/ServiceData.json'
import HomeData from '../Data/HomeData.json'
import UtlisData from '../Data/UtlisData.json'
import ServiceCard from '../componets/ServiceCard'
import Fade from 'react-reveal/Fade';
import Api from '../utlities/api'
import LoadingScreen from '../componets/Loading'
function Service() {

    const {label , title} = ServiceData;
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState();
    const [homeSetting, setHomeSetting] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
        Api.get("/home-setting").then(res => {
            setHomeSetting(res.data.data);
          });
        Api.get("/service").then(res => {
          setService(res.data.data);
        });
        setLoading(false); 
            } catch (error) {
                console.error('Error fetching data', error);
                setLoading(false); // Ensure loading is false even if there's an error
            }
        };

        fetchData();
      }, []);
      if (loading) {
        return <LoadingScreen />;  // You can replace this with a spinner or any loading indicator
      }
    return (
        <div className='team-section service-container'>
            <div className='team-hero-section'>
            <Fade left cascade>
                <div className='hero-detail'>
                    <span className='label'>SERVICES</span>
                    <p>{homeSetting?.service_title}</p>
                </div>
                <div className='hero-image'>
                    {/* <img src="/assets/images/about-us-illustration.png" /> */}
                </div>
                </Fade>
            </div>


            <div className='service-section mt-40'>
        
                <div className='service-card-row'>
                    {service?.map(item => (
                        <ServiceCard Data={item} />
                    ))}
                </div>
       
            </div>
            <EnquiryForm type={"simple"} />
            <Footer homeSetting={homeSetting} />
        </div>
    )
}

export default Service