import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import Api from '../utlities/api';
function EnquiryForm({ type }) {
    const [homeSetting, setHomeSetting] = useState();
    const [formData, setFormData] = useState({
        name: '',
        emailAddress: '',
        country: '',
        mobile: '',
        requirement: ''
    });


    const [SecondFormData, setSecondFormData] = useState({
        name: '',
        emailAddress: '',
        phone: '',
        skypeId: '',
        requirementsStage: '',
        fitStage: '',
        budget: '',
        projectTimeline: '',
        details: ''
    });
    const [SuccessMessage, setSuccessMessage] = useState(false);


    const SecondhandleChange = e => {
        // Update state with input values
        setSecondFormData({
            ...SecondFormData,
            [e.target.name]: e.target.value
        });
    }


    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        Api.get("/home-setting").then(res => {
            setHomeSetting(res.data.data);
        });
    }, []);

    const submitData = e => {
        e.preventDefault();

        let Name = formData?.name;
        let EmailAddress = formData?.emailAddress;
        let Country = formData?.country;
        let mobile = formData?.mobile;
        let requirement = formData?.requirement;

        let Subject = "New Enquiry Please Check...!"
        const templateHtml = `
                <div style="width: 100%; padding: 20px;">
                    <div style="width: 100%; padding: 15px 0px;">
                         <h3>Enquiry Request</h3>
                         <p>Hurry you have one more enquiry please check fast...</p>
                    </div>
                    <div style="width: 100%">
                        <h3>Details</h3>
                        <div style="padding: 15px 0px;">
                            <label>Name :</label>
                            <h4>${Name}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Email Address :</label>
                            <h4>${EmailAddress}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Country :</label>
                            <h4>${Country}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Contact Number :</label>
                            <h4>${mobile}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Requirement :</label>
                            <h4>${requirement}</h4>
                        </div>
                    </div>
                </div>
            `;

        Api.post('/sendEmail', {
            subject: Subject,
            message: templateHtml,
            sender: "support@easysoft.services"
        });

        setFormData({
            name: '',
            emailAddress: '',
            country: '',
            mobile: '',
            requirement: ''
        });

        setSuccessMessage(true);

        setTimeout(() => {
            setSuccessMessage(false);
        }, 5000);
    }


    const SecondsubmitData = async e => {
        e.preventDefault();

        // Destructure form data for easier access
        const { name, emailAddress, phone, skypeId, requirementsStage, fitStage, budget, projectTimeline, details } = SecondFormData;

        // Construct HTML message template
        const templateHtml = `
                <div style="width: 100%; padding: 20px;">
                    <div style="width: 100%; padding: 15px 0px;">
                         <h3>Enquiry Request</h3>
                         <p>New enquiry received, please review it promptly.</p>
                    </div>
                    <div style="width: 100%">
                        <h3>Details</h3>
                        <div style="padding: 15px 0px;">
                            <label>Name:</label>
                            <h4>${name}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Email Address:</label>
                            <h4>${emailAddress}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Phone Number:</label>
                            <h4>${phone}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Skype ID:</label>
                            <h4>${skypeId}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Requirements Stage:</label>
                            <h4>${requirementsStage}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Why do you think we are a good fit:</label>
                            <h4>${fitStage}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Budget:</label>
                            <h4>${budget}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Project Timeline:</label>
                            <h4>${projectTimeline}</h4>
                        </div>
                        <div style="padding: 15px 0px;">
                            <label>Details:</label>
                            <h4>${details}</h4>
                        </div>
                    </div>
                </div>
            `;

        try {
            // Send email using API endpoint
            await Api.post('/sendEmail', {
                subject: "New Enquiry Please Check...!",
                message: templateHtml,
                sender: "support@easysoft.services"
            });

            // Clear form fields after successful submission
            setSecondFormData({
                name: '',
                emailAddress: '',
                phone: '',
                skypeId: '',
                requirementsStage: '',
                fitStage: '',
                budget: '',
                projectTimeline: '',
                details: ''
            });

            // Display success message
            setSuccessMessage(true);

            // Hide success message after 5 seconds
            setTimeout(() => {
                setSuccessMessage(false);
            }, 5000);
        } catch (error) {
            console.error('Error sending email:', error);
            // Handle error or display error message
        }
    }


    if (type === "simple") {
        return (
            <Fade bottom cascade>
                <div className='contact-section'>
                    <div className='contact-detail'>
                        <div className='contact-label'><p>SEND INQUIRY</p></div>
                        <p className='main-title'>Let's connect and find if we are <span>“Match Made in Heaven⚡”</span></p>
                    </div>
                    <div className='contact-container'>
                        <div className='contact-form'>
                            <form onSubmit={submitData}>
                                {SuccessMessage && (
                                    <div className='sucess-message'>
                                        <p>Message sent successfully 😊</p>
                                    </div>
                                )}

                                <div className='fields'>
                                    <div className='half-field'>
                                        <label>Name</label>
                                        <input type="text" placeholder='Name' name="name" onChange={handleChange} value={formData.name} required />
                                        <span className='error-text'>Name is required!</span>
                                    </div>
                                    <div className='half-field'>
                                        <label>Work Email</label>
                                        <input type="text" placeholder='Email Address' name="emailAddress" onChange={handleChange} value={formData.emailAddress} required />
                                        <span className='error-text'></span>
                                    </div>

                                    <div className='half-field'>
                                        <label>Country</label>
                                        <select name="country" onChange={handleChange} value={formData.country} required>
                                            <option>Select Country</option>
                                            <option value="pakistan">Pakistan</option>
                                        </select>
                                        <span className='error-text'>Country Required</span>
                                    </div>
                                    <div className='half-field'>
                                        <label>Contact Number</label>
                                        <input type="text" placeholder='+001-1234567890' name="mobile" onChange={handleChange} value={formData.mobile} required />
                                        <span className='error-text'></span>
                                    </div>
                                    <div className='full-field'>
                                        <label>Requirement</label>
                                        <textarea type="text" name="requirement" onChange={handleChange} value={formData.requirement} required></textarea>
                                        <span className='error-text'></span>
                                    </div>
                                    <div className='full-field'>
                                        <button type='submit'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='contact-image'>
                            <img src="/assets/images/contact-us.png" alt="Contact Us" />
                        </div>
                    </div>
                </div>
            </Fade>
        )
    } else {
        return (
            <Fade bottom cascade>
                <div className='contact-section send-quote'>
                    <div className='contact-detail'>
                        <div className='contact-label'><p>SEND INQUIRY</p></div>
                        <p className='main-title'>Let's connect and find if we are <span>“Match Made in Heaven⚡”</span></p>
                    </div>
                    <div className='contact-container'>
                        <div className='contact-form'>
                            <form onSubmit={SecondsubmitData}>
                                {SuccessMessage && (
                                    <div className='sucess-message'>
                                        <p>Message sent successfully 😊</p>
                                    </div>
                                )}
                                <div className='fields'>
                                    <div className='half-field'>
                                        <label>Name</label>
                                        <input type="text" placeholder='Name' name='name' onChange={SecondhandleChange} value={SecondFormData.name} required />
                                        <span className='error-text'>Name is required!</span>
                                    </div>
                                    <div className='half-field'>
                                        <label>Work Email</label>
                                        <input type="text" placeholder='Email Address' name='emailAddress' onChange={SecondhandleChange} value={SecondFormData.emailAddress} required />
                                        <span className='error-text'></span>
                                    </div>
                                    <div className='half-field'>
                                        <label>Phone Number</label>
                                        <input type="text" placeholder='+001-1234567890' name='phone' onChange={SecondhandleChange} value={SecondFormData.phone} required />
                                        <span className='error-text'></span>
                                    </div>
                                    <div className='half-field'>
                                        <label>Skype ID</label>
                                        <input type="text" placeholder='-----' name='skypeId' onChange={SecondhandleChange} value={SecondFormData.skypeId} />
                                        <span className='error-text'></span>
                                    </div>
                                    <div className='full-field'>
                                        <label>Requirements Stage</label>
                                        <select name='requirementsStage' onChange={SecondhandleChange} value={SecondFormData.requirementsStage} required>
                                            <option disabled value="">- Select -</option>
                                            <option value={"I have a clear Scope of Work."}>I have a clear Scope of Work.</option>
                                            <option value={"I am Partly sure about my needs."}>I am Partly sure about my needs.</option>
                                            <option value={"It's just an idea."}>It's just an idea.</option>
                                        </select>
                                        <span className='error-text'>Required</span>
                                    </div>
                                    <div className='full-field'>
                                        <label>Why do you think we are good fit Stage</label>
                                        <select name='fitStage' onChange={SecondhandleChange} value={SecondFormData.fitStage} required>
                                            <option disabled value="">- Select -</option>
                                            <option value={"Work is related to product you created"}>Work is related to product you created</option>
                                            <option value={"I have seen your work and reviews"}>I have seen your work and reviews</option>
                                            <option value={"I am your repeat customer"}>I am your repeat customer</option>
                                            <option value={"I am not sure yet"}>I am not sure yet</option>
                                        </select>
                                        <span className='error-text'>Required</span>
                                    </div>
                                    <div className='full-field'>
                                        <label>What would be your budget investment budget?</label>
                                        <select name='budget' onChange={SecondhandleChange} value={SecondFormData.budget} required>
                                            <option disabled value="">- Select -</option>
                                            <option value="Under $1000">Under $1000</option>
                                            <option value="1000-5000">1000-5000</option>
                                            <option value="5000-10000">5000-10000</option>
                                            <option value="20000+">20000+</option>
                                        </select>
                                        <span className='error-text'>Required</span>
                                    </div>
                                    <div className='full-field'>
                                        <label>How soon do you need to finish the project?</label>
                                        <select name='projectTimeline' onChange={SecondhandleChange} value={SecondFormData.projectTimeline} required>
                                            <option disabled value="">- Select -</option>
                                            <option value="Urgent">Urgent</option>
                                            <option value="Moderate">Moderate</option>
                                            <option value="Flexible">Flexible</option>
                                            <option value="Very Flexible">Very Flexible</option>
                                        </select>
                                        <span className='error-text'>Required</span>
                                    </div>
                                    <div className='full-field'>
                                        <label>Tell us about your needs in detail</label>
                                        <textarea name='details' onChange={SecondhandleChange} value={SecondFormData.details} required></textarea>
                                        <span className='error-text'>Required</span>
                                    </div>
                                    <div className='full-field'>
                                        <button type='submit'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='contact-information'>
                            <div className='blue-container-box'>
                                <h3 className='main-title'>Get in touch</h3>
                                <p className='main-description'>{homeSetting?.contact_description}</p>
                                <div className='social-container'>
                                    <Link className='social' to={homeSetting?.facebook_link}>
                                        <i className='fa fa-facebook'></i>
                                    </Link>
                                    <Link className='social' to={homeSetting?.instagram_link}>
                                        <i className='fa fa-instagram'></i>
                                    </Link>
                                    <Link className='social' to={homeSetting?.twitter_link}>
                                        <i className='fa fa-twitter'></i>
                                    </Link>
                                </div>
                                <ul>
                                    <li>
                                        <i className='fa fa-phone'></i> <Link>{homeSetting?.mobile_number}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>

        )
    }
}

export default EnquiryForm