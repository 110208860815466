
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Use BrowserRouter for web apps
import Topbar from './componets/Topbar'; // Import Topbar here if needed
import LandingPage from './pages/LandingPage';
import Team from './pages/Team';
import Portfolio from './pages/Portfolio';
import Service from './pages/Service';
import ServicePage from './pages/service/ServicePage';
import PortfolioPage from './pages/portfolio/PortfolioPage';
import ProductPage from './pages/product/ProductPage';
import Product from './pages/Product';
import MakeQuote from './pages/make-quote';
import Tremscondition from './pages/Tremscondition';
import NotFound from './componets/404';
import "./assets/css/main.css";
import "./assets/css/responsive.css";

import ScrollToTop from "./componets/ScrollToTop";


function App() {
  return (
    <BrowserRouter>
      <div className="main-container">
        <div className="main-content">
          <Topbar /> {/* You can place Topbar outside the Router if it's meant to be always visible */}
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/team" element={<Team />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/service" element={<Service />} />
            <Route path="/service/:slug" element={<ServicePage />} />
            <Route path="/portfolio/:slug" element={<PortfolioPage />} />
            <Route path="/products" element={<Product />} />
            <Route path="/product/:slug" element={<ProductPage />} />
            <Route path="/make-quote" element={<MakeQuote />} />
            <Route path="/trems-conditions" element={<Tremscondition />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

