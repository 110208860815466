import React, { useEffect, useState } from 'react'
import EnquiryForm from '../componets/EnquiryForm'
import Footer from '../componets/footer'
import Fade from 'react-reveal/Fade';
import Api from '../utlities/api';
import LoadingScreen from '../componets/Loading';

function MakeQuote() {
  const [homeSetting, setHomeSetting] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchData = async () => {
      try {
        Api.get("/home-setting").then(res => {
          setHomeSetting(res.data.data);
        });
        setLoading(false); // Set loading to false after all data is fetched
      } catch (error) {
        console.error('Error fetching data', error);
        setLoading(false); // Ensure loading is false even if there's an error
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingScreen />;  // You can replace this with a spinner or any loading indicator
  }

  return (
    <div className='service-page-container make-quote-form'
    >
      <div className='service-hero-section'>
        <Fade left cascade>
          <div className='hero-detail'>
            <span className='label'>CONTACT US</span>
            <h3>{homeSetting?.contact_title}</h3>
            <p>{homeSetting?.contact_description}</p>
          </div>
          <div className='hero-image'>
            {/* <img src="/assets/images/about-us-illustration.png" /> */}
          </div>
        </Fade>
      </div>
      <EnquiryForm type="make-qoute" />
      <Footer homeSetting={homeSetting} />
    </div>
  )
}

export default MakeQuote